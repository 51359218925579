import { PropsWithChildren, memo, useMemo } from 'react'

interface Props {
    className?: string
    py?: 'sm' | 'md' | 'lg' | 'none';
    width?: 'sm' | 'md' | 'lg';
}

const PageLayout: React.FC<PropsWithChildren<Props>> = ({
    children = null,
    className = '',
    py = 'none',
    width = 'lg'
}) => {

    const pyClass = useMemo(() => {
        if ('sm' === py) return 'py-5';
        if ('md' === py) return 'py-5 md:py-10';
        if ('lg' === py) return 'py-5 md:py-20';
    }, [py])

    const widthClass = useMemo(() => {
        if ('sm' === width) return 'max-w-2xl';
        if ('md' === width) return 'max-w-4xl';
        if ('lg' === width) return 'max-w-6xl';
    }, [width])

    return (
        <div className={`${className} ${widthClass} ${pyClass} px-4 mx-auto`}>
            {children}
        </div>
    )
}

export default memo(PageLayout);